
<template>
    <div>
        <div class="filter-panel">
          <CSSelect style="margin-right: 20px; width: 185px">
            <el-date-picker
                v-model="queryParams.startTime"
                type="month"
                value-format="yyyy-MM"
                placeholder="选择月"
                prefix-icon="el-icon-time"
                :editable="false"
            >
            </el-date-picker>
          </CSSelect>
          <CSSelect v-show="[1001, 1002, 1007, 1008].includes(Number(staffInfo.dutyType))" style="margin-right: 20px;">
            <select
                class="form-control"
                v-model="queryParams.dutyType"
            >
              <option value="">
                全部部门
              </option>
              <option
                  v-for="department in orgList"
                  :key="department.code"
                  :value="department.dutyType"
              >
                {{ department.name }}
              </option>
            </select>
          </CSSelect>
          <CSSelect style="margin-right: 20px;">
            <select v-model="queryParams.state">
              <option value="">考勤状态不限</option>
              <option :value="1">正常</option>
              <option :value="2">迟到</option>
              <option :value="3">早退</option>
              <option :value="4">无排班打卡</option>
              <option :value="5">旷工</option>
              <option :value="6">上班缺卡</option>
              <option :value="7">下班缺卡</option>
            </select>
          </CSSelect>
          <input type="text" style="margin-left: 0; margin-right: 30px;" class="cs-input" placeholder="搜索员工" v-model="staffKeyword">
          <button
              type="button"
              class="btn btn-primary btn-sm"
              @click="refreshAttendanceLog"
          >
            查询
          </button>
        </div>

      <div class="result-panel" style="overflow-y: auto">
        <div class="result-panel-header text-right" style="min-width: 1820px">
          <button class="btn btn-primary sticky-right" @click="downloadExcel">下载考勤数据</button>
        </div>
        <div style="padding: 20px; box-sizing: border-box;display: inline-block;min-width: 100%;">
            <table class="table modal-inner-table">
                <thead>
                <tr
                    v-html="getThead(currentDate)"
                ></tr>
                </thead>
                <tbody>
                <template v-if="attendanceList.length > 0">
                    <tr v-for="(item, attendanctIndex) in attendanceList" :key="attendanctIndex">
                        <td class="text-center">
                            {{ DEPARTMENT_TYPE_STR[item.dutyType] }}
                        </td>
                        <td class="text-center">
                            {{ item.name }}
                        </td>
                        <template
                            v-for="index in currentMonthDays"
                        >
                            <template
                                v-if="item.schedules && item.schedules[`${currentDate}-${index.toString().padStart(2, '0')}`]"
                            >
                                <td class="text-center" :key="index"
                                    @click="lookThatDayCheckInData(item.schedules[`${currentDate}-${index.toString().padStart(2, '0')}`])"
                                    v-html="getThatDayCheckInLog(item.schedules[`${currentDate}-${index.toString().padStart(2, '0')}`])"
                                >
                                </td>
                            </template>
                            <template v-else>
                                <td
                                    class="text-center"
                                    :key="index + '_'"
                                >
                                    -
                                </td>
                            </template>
                        </template>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>

      </div>
    </div>
</template>
<script>
import { ORG_LEVEL, DEPARTMENT_TYPE_STR, DEPARTMENT_TYPES } from "@/constant";
import {
  queryDepartmentUrl,
  queryAttendanceLogsUrl,
  downloadAttendanceDataExcelUrl
} from "@/requestUrl";
import CSSelect from "@/components/common/CSSelect";

export default {
    props: {},
    created(){
      window.addEventListener("keydown",this.refreshAttendanceLogDown);
    },
    beforeDestroy(){
      window.removeEventListener("keydown",this.refreshAttendanceLogDown);
    },
    deactivated(){
      window.removeEventListener("keydown",this.refreshAttendanceLogDown);
    },
    components: {
        CSSelect,
    },
    data() {
        return {
          DEPARTMENT_TYPE_STR,
            staffInfo: this.$vc.getCurrentStaffInfo(),
            orgList: [],
            currentDate: dayjs().format("YYYY-MM"),
            queryParams: {
              startTime: dayjs().format("YYYY-MM"),
              dutyType: "",
              state: '',
            },
            staffKeyword: '',
            currentMonthDays: "",
            attendanceList: [],
            attendanceState: {
              1: '',  // 正常
              2: '迟到',
              3: '早退',
              4: '无排班打卡',
              5: '旷工',
              6: '上班缺卡',
              7: '下班缺卡',
            }
        };
    },

    mounted() {
        this.getOrgList();
        this.queryAttendanceLog();
        this.$vc.on(this.$route.path, "pagination", "page_event", (_currentPage) => {
            this.queryAttendanceLog(_currentPage);
        });
    },
    methods: {
      refreshAttendanceLogDown(e){
        if(e.keyCode == 13){
          this.refreshAttendanceLog();
        }
      },
        // 查看当天考勤打卡记录
        lookThatDayCheckInData(data) {
          const {state, createTime} = data;
          if (createTime.length <= 2) {
            return ;
          }
          let logView = '';
          createTime.map((time, index) => {
            const stateText = this.attendanceState[state[index]];
            logView += `<div class="${state[index] !== 1 ? 'error-text' : ''}">${![6, 7, 5].includes(state[index]) ? time : ''}${stateText || ''}</div>`
          })
          this.$CSDialog.alert({
            title: '考勤数据',
            messageHtml: `<div class="text-center" style="max-height: 220px;overflow-y: auto;">${logView}</div>`
          })
        },
        // 获取当天打卡记录
        getThatDayCheckInLog(data) {
          let logView = '';
          const {state, createTime} = data;
          if (state.includes(5)) {
            logView += `<div class="error-text td-cell">旷工</div>`
          } else {
            createTime.forEach((time, index) => {
              const stateText = this.attendanceState[state[index]];
              if (index === 0 || (index > 0 && index === createTime.length - 1)) {
                logView += `<div class="td-cell ${state[index] !== 1 ? 'error-text' : ''}">
                    ${![6, 7].includes(state[index]) ? time : ''}${stateText || ''}
                </div>`
              }
            })
          }
          if (createTime.length > 2) {
            logView += '<div class="angle-mark"></div>'
          }
          return logView;
        },
        // 下载excel
        downloadExcel() {
          this.$fly.post(downloadAttendanceDataExcelUrl, {
            startTime: this.queryParams.startTime+'-01'
          }, {
            responseType: 'arraybuffer',
            headers: {
              notParse: true,
            }
          })
          .then(res => {
            const blob = new Blob([res], { type: 'application/vnd.ms-excel'});
            const fileName = `${this.queryParams.startTime}考勤.xlsx`;
            if ("download" in document.createElement("a")) {
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href);
              document.body.removeChild(elink);
            } else {
              navigator.msSaveBlob(blob, fileName);
            }
          })
        },
        getOrgList() {
            this.$fly
                .post(queryDepartmentUrl, {
                    regionCode: this.$vc.getCurrentRegion().code,
                    code: "",
                    name: "",
                    dutyType: "",
                    parentCode: "",
                    level: ORG_LEVEL.DEPARTMENT,
                    pageNo: 1,
                    pageSize: 100,
                })
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.orgList = res.data.filter(item => ![
                            DEPARTMENT_TYPES.DIRECTOR, DEPARTMENT_TYPES.MANAGER, DEPARTMENT_TYPES.PRESIDENT_OFFICE
                        ].includes(+item.dutyType)
                    );
                });
        },
        refreshAttendanceLog() {
            this.currentDate = this.queryParams.startTime;
            this.queryAttendanceLog();
        },
        getThead(date) {
            const isLeapYear = Number(date.slice(0, 4)) % 4 === 0;
            let dayArr = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
            if (isLeapYear) {
                dayArr[1] = 29;
            }
            this.currentMonthDays = dayArr[Number(date.slice(5)) - 1];
            const weekMapping = {
                0: "日",
                1: "一",
                2: "二",
                3: "三",
                4: "四",
                5: "五",
                6: "六",
            };
            let tr = '<th style="min-width: 70px;">部门</th><th style="min-width: 70px;">员工</th>';
            for (let i = 1; i <= dayArr[Number(date.slice(5)) - 1]; i++) {
                tr += `<th>
                            <div>${
                                weekMapping[
                                    dayjs(
                                        date.concat(this.$vc.prefixZero(i))
                                    ).day()
                                ]
                            }</div>
                            <div>${this.$vc.prefixZero(i)}</div>
                        </th>`;
            }
            return tr;
        },
        queryAttendanceLog() {
            const params = {
              ...this.queryParams,
              regionCode: this.$vc.getCurrentRegion().code,
            }
            params.startTime = params.startTime+'-01';
            //发送get请求
            this.$fly.post(queryAttendanceLogsUrl, params).then((res) => {
              this.attendanceList = res.data.map(item => {
                  const schedules = {};
                  item.attendanceData.forEach(attendance => {
                    schedules[attendance.createDate] = attendance;
                  })
                  item.schedules = schedules;
                  return item;
                }).filter(staff => {
                  if ( this.staffKeyword !== '') {
                    return staff.name.includes(this.staffKeyword);
                  }
                  return true;
              })
            });
        },
    },
};
</script>
<style lang="stylus" scoped>
.table.modal-inner-table
  --modal-inner-table-font-size 14px
  --modal-inner-cell-min-width 50px
  td, th
    min-height 69px
    position relative
    overflow hidden
    .td-cell
      white-space nowrap
    div
      min-width 40px
</style>
